body {
    background: whitesmoke;
}
.content {
    margin: 0 auto;
    text-align: center;
}
.footer-container {
    margin: 30px auto;
    text-align: center;
    width: 100%;
}